import PropTypes from 'prop-types';
import React from 'react';

import DefaultImage from '@/assets/images/Location-Hero-Placeholder@2x.png';
import { Breadcrumb } from '@/design-system/atoms/Breadcrumb';
import { Button } from '@/design-system/atoms/Button';
import { ButtonGroup } from '@/design-system/atoms/ButtonGroup';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { IconDescription } from '@/design-system/atoms/IconDescription';
import { LazyIconClock } from '@/design-system/atoms/Icons/IconClock/Lazy';
import { LazyIconMapPin } from '@/design-system/atoms/Icons/IconMapPin/Lazy';
import { LazyIconNavigation } from '@/design-system/atoms/Icons/IconNavigation/Lazy';
import { LazyIconPhone } from '@/design-system/atoms/Icons/IconPhone/Lazy';
import { LazyIconPrinter } from '@/design-system/atoms/Icons/IconPrinter/Lazy';
import { Image } from '@/design-system/atoms/Image';
import { LocationOpenOrClosed } from '@/design-system/atoms/LocationOpenOrClosed';
import { StarRatings } from '@/design-system/atoms/StarRatings';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';
import Typography from '@/design-system/atoms/Typography';
import { useLocalStorageGeolocation } from '@/hooks/useLocalStorageGeolocation';
import { useLocationWaitTime } from '@/hooks/useLocationWaitTime/useLocationWaitTime';
import { normalizeAddress } from '@/utils/normalizeAddress';
import { normalizeWorkingHours } from '@/utils/normalizeWorkingHours';

import addressPropType from '../../../custom-prop-types/address';
import { workingHoursType } from '../../../custom-prop-types/workingHours';
import styles from './LocationDetailHero.module.scss';

const ONE_HOUR_IN_SECONDS = 60 * 60;

/**
 * The Location Detail Hero is used on the Location Detail templates. There are
 * 3 main types of location detail hero variants: Hospitals, Departments, and
 * Urgent Care.
 */
const LocationDetailHero = (props) => {
    const {
        variant = 'hospital',
        breadcrumbs,
        title,
        ratings,
        numberOfRate,
        parentLocationName,
        rootLocationName,
        buildingName,
        address,
        phoneNumber,
        faxNumber,
        links,
        getCareLink,
        workingHours,
        overrideMessage,
        temporarilyClosed,
        image,
        alertMessage,
        locationId,
        waitTime: hasWaitTime,
        epicDepartmentId,
    } = props;
    const displayWorkingHours = workingHours?.displayWorkingHours || false;
    const NUMBER_OF_DECIMALS = 2;
    const infoList = [
        [normalizeAddress(address), address?.googleMapsUrl, LazyIconMapPin],
        [phoneNumber, `tel:${phoneNumber}`, LazyIconPhone],
        [faxNumber, '', LazyIconPrinter],
    ];
    const [localStorageGeolocation] = useLocalStorageGeolocation();
    const [distance, setDistance] = React.useState(null);

    const { showParentAndBuildingName, showButtonGroup, showWaitTime } = React.useMemo(() => {
        if (variant === 'hospital') {
            return {
                showParentAndBuildingName: false,
                showButtonGroup: false,
                showWaitTime: false,
            };
        }

        if (variant === 'department') {
            return {
                showParentAndBuildingName: true,
                showButtonGroup: true,
                showWaitTime: false,
            };
        }

        return {
            showParentAndBuildingName: true,
            showButtonGroup: true,
            showWaitTime: true,
        };
    }, [variant]);

    const [waitTimeInSeconds, waitTime] = useLocationWaitTime({
        locationType: variant,
        hasWaitTime,
        epicDepartmentId,
    });

    const analyticsObj = React.useMemo(() => {
        const { is24Hours, isOpen } = normalizeWorkingHours({ workingHours, temporarilyClosed });
        return {
            componentName: 'Location Detail Hero',
            componentTitle: title,
            locationName: title,
            locationParent: parentLocationName,
            locationAddress: normalizeAddress(address).replaceAll('\n', ','),
            locationPhone: phoneNumber,
            locationOpen: is24Hours ? 'Open 24 Hours' : isOpen ? 'Open' : 'Closed',
            locationWait: waitTime || '',
            zipCode: localStorageGeolocation?.zipCode,
        };
    }, [
        title,
        parentLocationName,
        address,
        phoneNumber,
        workingHours,
        temporarilyClosed,
        waitTime,
        localStorageGeolocation,
    ]);

    const getLocationDistance = async (latitude, longitude, locid, signal = null) => {
        const response = await fetch(
            `/bin/ahs/locdistance?lat=${latitude}&long=${longitude}&locId=${locid}`,
            { signal },
        );

        if (!response.ok) {
            throw new Error(`Error: Status ${response.status},${response.message}`);
        }

        return response.json();
    };

    React.useEffect(() => {
        const controller = new AbortController();
        const fetchDistance = async () => {
            if (!locationId || !localStorageGeolocation?.lat || !localStorageGeolocation?.lng) {
                return;
            }
            try {
                const distanceData = await getLocationDistance(
                    localStorageGeolocation?.lat,
                    localStorageGeolocation?.lng,
                    locationId,
                    controller.signal,
                );
                setDistance(distanceData?.distanceInMiles?.toFixed(NUMBER_OF_DECIMALS));
            } catch (err) {
                if (err.name === 'AbortError') {
                    console.log('Fetch Aborted');
                    return;
                }
                setDistance(null);
            }
        };

        if (localStorageGeolocation?.lat && localStorageGeolocation?.lng) fetchDistance();

        return () => controller.abort();
    }, [localStorageGeolocation, locationId]);

    return (
        <Theme name={Theme.NAME.LOCATIONS}>
            <div className={styles['location-detail-hero']}>
                <ContentContainer>
                    {breadcrumbs?.length > 0 && (
                        <Breadcrumb
                            items={breadcrumbs}
                            analytics={{
                                componentName: 'Location Detail Hero',
                                componentTitle: title,
                            }}
                        />
                    )}

                    {title && (
                        <div className={styles['location-detail-hero__title']}>
                            <Grid isContainerQuery>
                                <GridColumn colSpan={{ sm: 4, md: 6, lg: 7 }}>
                                    <Title
                                        variant={Title.VARIANT.H2}
                                        tag={Title.TAG.H1}
                                        content={title}
                                    />
                                </GridColumn>
                            </Grid>
                        </div>
                    )}

                    {typeof ratings === 'number' && ratings > 0 && (
                        <div className={styles['location-detail-hero__ratings']}>
                            <span>{ratings?.toFixed(1)}</span>
                            <StarRatings total={5} ratings={ratings} />
                            {numberOfRate > 0 && <span>({numberOfRate})</span>}
                        </div>
                    )}

                    <div className={styles['location-detail-hero__card']}>
                        <Grid isContainerQuery>
                            <GridColumn colSpan={{ sm: 4, md: 3, lg: 6 }}>
                                <div className={styles['location-detail-hero__information']}>
                                    {showParentAndBuildingName && (
                                        <div>
                                            {(rootLocationName || parentLocationName) && (
                                                <Typography
                                                    tag={Typography.TAG.P}
                                                    variant={Typography.VARIANT.T2B}
                                                    content={`at ${
                                                        rootLocationName || parentLocationName
                                                    }`}
                                                />
                                            )}

                                            {buildingName && (
                                                <Typography
                                                    tag={Typography.TAG.P}
                                                    variant={Typography.VARIANT.T2}
                                                    content={buildingName}
                                                />
                                            )}
                                        </div>
                                    )}

                                    {infoList.length > 0 && (
                                        <div className={styles['location-detail-hero__contact']}>
                                            {infoList[0] &&
                                                infoList[0][0] &&
                                                (([content, href, Icon]) => (
                                                    <IconDescription iconComponent={Icon}>
                                                        <Button
                                                            href={href}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            buttonStyle={Button.STYLE.TEXT_SMALL}
                                                            label={content}
                                                            alignment="left"
                                                            analytics={{
                                                                ...analyticsObj,
                                                                interactionType:
                                                                    'location_address_cta',
                                                            }}
                                                        />
                                                    </IconDescription>
                                                ))(infoList[0])}

                                            <div>
                                                {infoList[1] &&
                                                    infoList[1][0] &&
                                                    (([content, href, Icon]) => (
                                                        <IconDescription iconComponent={Icon}>
                                                            <Button
                                                                href={href}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                buttonStyle={
                                                                    Button.STYLE.TEXT_SMALL
                                                                }
                                                                label={content}
                                                                analytics={{
                                                                    ...analyticsObj,
                                                                    interactionType:
                                                                        'location_phone_cta',
                                                                }}
                                                            />
                                                        </IconDescription>
                                                    ))(infoList[1])}

                                                {infoList[2] &&
                                                    infoList[2][0] &&
                                                    (([content, _href, Icon]) => (
                                                        <IconDescription iconComponent={Icon}>
                                                            <Typography
                                                                variant={Typography.VARIANT.T2}
                                                                content={content}
                                                            />
                                                        </IconDescription>
                                                    ))(infoList[2])}
                                            </div>
                                        </div>
                                    )}

                                    {showButtonGroup && links && links.length > 0 && (
                                        <div>
                                            <ButtonGroup
                                                buttons={links}
                                                className={
                                                    styles['location-detail-hero__button-wrapper']
                                                }
                                                normalizedButtons
                                                analytics={{
                                                    ...analyticsObj,
                                                    interactionType: 'location_primary_cta',
                                                }}
                                                secondButtonAnalytics={{
                                                    interactionType: 'location_cta',
                                                }}
                                            />
                                        </div>
                                    )}

                                    <div
                                        className={
                                            styles['location-detail-hero__distance-and-wait-time']
                                        }
                                    >
                                        {localStorageGeolocation?.zipCode && distance && (
                                            <IconDescription iconComponent={LazyIconNavigation}>
                                                <Typography
                                                    variant={Typography.VARIANT.T2}
                                                    content={`${distance} miles away from ${localStorageGeolocation?.zipCode}`}
                                                />
                                            </IconDescription>
                                        )}

                                        {showWaitTime && waitTime && (
                                            <IconDescription iconComponent={LazyIconClock}>
                                                <div
                                                    className={
                                                        styles[
                                                            'location-detail-hero__wait-time-wrapper'
                                                        ]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                'location-detail-hero__wait-time'
                                                            ]
                                                        }
                                                    >
                                                        <Typography
                                                            variant={Typography.VARIANT.T2}
                                                            content={`Wait: ${waitTime}`}
                                                        />
                                                    </div>

                                                    {waitTimeInSeconds >= ONE_HOUR_IN_SECONDS &&
                                                        getCareLink && (
                                                            <div>
                                                                <Button
                                                                    href={getCareLink}
                                                                    target="_self"
                                                                    buttonStyle={
                                                                        Button.STYLE.TEXT_SMALL
                                                                    }
                                                                    label={'Get Care Sooner'}
                                                                    analytics={{
                                                                        ...analyticsObj,
                                                                        interactionType:
                                                                            'location_internal_link',
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </IconDescription>
                                        )}
                                    </div>

                                    <div className={styles['location-detail-hero__divider']} />

                                    <LocationOpenOrClosed
                                        workingHours={workingHours}
                                        overrideMessage={overrideMessage}
                                        temporarilyClosed={temporarilyClosed}
                                        showAll={displayWorkingHours}
                                    />
                                </div>
                            </GridColumn>
                            <GridColumn
                                colSpan={{ sm: 4, md: 3, lg: 6 }}
                                className={styles['location-detail-hero__media']}
                            >
                                {image && image.src ? (
                                    <Image
                                        {...image}
                                        defaultAspectRatio={Image.ASPECT_RATIO['16:9']}
                                        fallbackSrc={DefaultImage}
                                    />
                                ) : (
                                    <Image
                                        src={DefaultImage}
                                        defaultAspectRatio={Image.ASPECT_RATIO['16:9']}
                                        decorative
                                    />
                                )}
                            </GridColumn>
                            {alertMessage && (
                                <GridColumn colSpan={{ sm: 4, md: 6, lg: 12 }}>
                                    <Theme
                                        name={Theme.NAME.SPECIALTIES}
                                        className={styles['location-detail-hero__alert-message']}
                                    >
                                        <Typography
                                            tag={Typography.TAG.P}
                                            variant={Typography.VARIANT.T3}
                                            content={alertMessage}
                                        />
                                    </Theme>
                                </GridColumn>
                            )}
                        </Grid>
                    </div>
                </ContentContainer>
            </div>
        </Theme>
    );
};

LocationDetailHero.propTypes = {
    /**
     * 3 possible variants: `hospital`, `department` and `urgent-care`
     */
    variant: PropTypes.oneOf(['hospital', 'department', 'urgent-care']),
    /**
     * Breadcrumbs props
     */
    breadcrumbs: Breadcrumb.propTypes.items,
    /**
     * Location title
     */
    title: PropTypes.string,
    /**
     * Location ratings
     */
    ratings: PropTypes.number,
    /**
     * Number of people that rate the location
     */
    numberOfRate: PropTypes.number,
    /**
     * Parent location name
     */
    parentLocationName: PropTypes.string,
    /**
     * Building name
     */
    buildingName: PropTypes.string,
    /**
     * Link address object
     */
    address: addressPropType,
    /**
     * Phone number
     */
    phoneNumber: PropTypes.string,
    /**
     * FAX number
     */
    faxNumber: PropTypes.string,
    /**
     * CTA links
     */
    links: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    /**
     * Distance between the location and the user
     */
    distance: PropTypes.string,
    /**
     * Wait time
     */
    waitTime: PropTypes.string,
    /**
     * Get care link
     */
    getCareLink: PropTypes.string,
    /**
     * Working hours
     */
    workingHours: workingHoursType,
    /**
     * Override message
     */
    overrideHoursMessage: PropTypes.string,
    /**
     * Is temporarily closed
     */
    temporarilyClosed: PropTypes.bool,
    /**
     * Location image
     */
    image: PropTypes.shape(Image.propTypes),
    /**
     * Alert message
     */
    alertMessage: PropTypes.string,
    /**
     * location Id
     */
    locationId: PropTypes.string,
};

export default LocationDetailHero;
